enum statusTypes {
  "ACCEPTED" = "ACCEPTED",
  "NO_REQUEST" = "NO_REQUEST",
  "USER_REQUEST" = "USER_REQUEST",
  "REQUEST" = "REQUEST",
  "CONFIRMED" = "CONFIRMED",
  "NOT_DECIDED" = "NOT_DECIDED",
  "REJECTED" = "REJECTED",
  "REQUESTED" = "REQUESTED",
  "INFO_WAIVER_CANCEL" = "INFO_WAIVER_CANCEL"
}
export default statusTypes;
